import { type AnalyticsBrowser } from '@segment/analytics-next';
import { useMemo } from 'react';

import { type User } from '../types';
import { isGuest } from '../types/user';
import { useAnalytics } from './AnalyticsContext';

export interface UserAnalytics {
  identify(user: User): void;
  unidentify(): void;
}

class UserAnalyticsImpl implements UserAnalytics {
  constructor(readonly analytics: AnalyticsBrowser) {}

  identify(user: User) {
    const params = {
      email: user.email,
      username: user.username,
      role: user.role,
      firstName: user.organizer?.firstName,
      lastName: user.organizer?.lastName,
      createdAt: user.organizer?.createdAt,
      invitedAt: user.organizer?.invitedAt ?? undefined,
      activatedAt: user.organizer?.activatedAt ?? undefined,
      activated: user.organizer?.activated,
      orgId: user.organizer?.orgId,
      organizationName: user.organizer?.organization?.name,
      organizationRole: user.organizer?.role,
    };
    if (isGuest(user)) {
      this.analytics.identify(params, undefined);
    } else {
      this.analytics.identify(user.id, params);
    }
  }

  unidentify() {
    this.analytics.reset();
  }
}

export function useUserAnalytics(): UserAnalytics {
  const analytics = useAnalytics();
  return useMemo(() => new UserAnalyticsImpl(analytics), [analytics]);
}
